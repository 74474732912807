import React from "react"
import { Link } from "gatsby"

const MainNavigation = () => {
  return (
    <div className="main-nav">
      <div className="main-nav-links">
        <ul>
          <li>
            <Link to="/aboutus">
              <span>ABOUT US</span>
            </Link>
          </li>
          <li>
            <Link to="/whatwedo">
              <span>WHAT WE DO</span>
            </Link>
          </li>
          <li className="live-streaming-link" style={{ display: "none" }}>
            <Link to="/livestreaming">
              <span>LIVE STREAMING</span>
            </Link>
          </li>
          <li>
            <a href="mailto:hello@smileysound.com" target="_top">
              <span>SAY HI!</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MainNavigation
