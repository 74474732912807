import React from "react"
import {
  faPatreon,
  faFacebook,
  faInstagram,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function SocialBlock() {
  return (
    <div className="social-block">
      <iframe
        id="t"
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url==https%3A//api.soundcloud.com/playlists/1055975191%3Fsecret_token%3Ds-bZB2yTwaAyb&auto_play=true&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=false&amp;color=000000&ampdowlonad=true?iframe=true&nofocus=y"
      ></iframe>

      <a href="https://soundcloud.com/smileysoundtracks" target="_blank">
        <FontAwesomeIcon icon={faSoundcloud} />
      </a>
      <a href="https://www.instagram.com/smiley.sound/?hl=en" target="_blank">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </div>
  )
}
