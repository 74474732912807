import React from "react"
import SEO from "../components/SEO"
import { useStaticQuery, graphql } from "gatsby"
import MainNavigation from "../components/MainNavigation"
import SocialBlock from "../components/SocialBlock"
import PageDownArrow from "../components/PageDownArrow"
import Logo from "../components/Logo"
import GIF from "../../static/main.gif"
import { Link } from "gatsby"

const Index = ({ location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="home-paige">
      {" "}
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
      />
      <Logo />
      <div className="main-social-container">
        <SocialBlock />
      </div>
      <MainNavigation />
      <div className="gif-container">
        <img id="gif" src={GIF} alt="" />
      </div>
      <PageDownArrow text="learn more..." />
    </div>
  )
}

export default Index
