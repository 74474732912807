import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

const PageDownArrow = props => {
  return (
    <div className="arrow-page-down">
      <Link to="/livestreaming">
        {" "}
        <p className="arrow-down-description">{props.text}</p>
      </Link>
    </div>
  )
}
export default PageDownArrow
